var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    attrs: {
      "id": "topnav-login"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "border-bottom-nav",
    attrs: {
      "id": "navigation"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('ul', {
    directives: [{
      name: "scroll-spy-active",
      rawName: "v-scroll-spy-active",
      value: {
        selector: 'li.has-submenu'
      },
      expression: "{ selector: 'li.has-submenu' }"
    }],
    staticClass: "navigation-menu mb-0"
  }, [_c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "https://awarisgroup.com/"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left mr-1"
  }), _vm._v(_vm._s(_vm.$t("navbar.homepage")))])])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "text-white text-right pr-4 btn-hai"
  }, [_c('p', {
    staticClass: "p-lang"
  }, [_vm._v(" " + _vm._s(_vm.$t("lang")) + " "), _c('span', {
    staticClass: "lang-bg ml-2"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('ms');
      }
    }
  }, [_vm._v(" BM ")]), _vm._v(" | "), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('en');
      }
    }
  }, [_vm._v(" ENG ")])])])])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "margin-top": "100px"
    }
  }, [_c('div', {
    staticClass: "bg-overlay bg-overlay-white bg-overlay-img"
  }), _c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "margin-bottom": "50px"
    }
  }, [_c('div', {
    staticClass: "card verification-page bg-white shadow rounded-login border-0"
  }, [_c('div', {
    staticClass: "card-body-login"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('h5', {
    staticClass: "m-0 title text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.will-desc1")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "table-responsive bg-light shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("no-wasiat")) + " ")]), _c('td', {
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v(" " + _vm._s(_vm.wasiat ? _vm.wasiat.wasiat_no : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("type")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.wasiat_type == 1 ? "Wasiat Islam" : "Wasiat Konvensional") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("date-of-will")) + " ")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("getConvertDate")(_vm.wasiat.created_at)) + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("price")) + " ")]), _c('td', [_vm._v("RM" + _vm._s(_vm.wasiat.price))]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.status == 0 ? this.$t("on-going-will") : _vm.wasiat.status == 1 ? this.$t("org.wait-confirmation") : _vm.wasiat.status == 2 ? this.$t("approved-will") : this.$t("revoke-will1")) + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("organization")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.organization ? _vm.wasiat.organization.name : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.branch ? _vm.wasiat.branch.branch_name : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("agent-name")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.agent ? _vm.wasiat.agent.name : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("agent-phone-no")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.agent ? _vm.wasiat.agent.mobile_number : "-") + " ")])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('h5', {
    staticClass: "m-0 title text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("testator-desc")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "table-responsive bg-light shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("designation")) + " ")]), _c('td', {
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.designation : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.name : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("new-ic")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.ic_number : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("old-ic")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.other_ic_number : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("gender")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.gender : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("citizen")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.nationality : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.mobile_number : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("home-telno")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.home_phone_number : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.email : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("mailing-address")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.correspondence_address : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.correspondence_postcode : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.correspondence_city : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.correspondence_state : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("permanent-address")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.permanent_address : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.permanent_postcode : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.permanent_city : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.permanent_state : "-") + " ")])])])])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('h5', {
    staticClass: "m-0 title text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("necessary-info-to-contact")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "table-responsive bg-light shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " ")]), _c('td', {
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.name : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary",
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("relationship-with-heirs")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.relationship : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.mobile_number : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("home-telno")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.home_phone_number : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t("mailing-address")) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.address : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.postcode : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.city : "-") + " ")]), _c('th', {
    staticClass: "bg-secondary"
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('td', [_vm._v(" " + _vm._s(_vm.wasiat.heir ? _vm.wasiat.heir.state : "-") + " ")])])])])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center mt-4 mt-sm-0"
  }, [_c('div', {
    staticClass: "mt-md-4 mt-3 mt-sm-0"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("agree2")))])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center mt-4 mt-sm-0"
  }, [_c('div', {
    staticClass: "mt-md-4 mt-3 mt-sm-0"
  }, [_c('button', {
    staticClass: "btn btn-primary mr-2",
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")])])])])])])])])]), _c('footer', {
    staticClass: "footer-login footer-bar"
  }, [_c('div', {
    staticClass: "container text-center"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 mb-3"
  }, [_c('div', {
    staticClass: "text-sm-center"
  }, [_c('p', {
    staticClass: "mb-3"
  }, [_c('strong', [_vm._v("©2021 AWARIS. " + _vm._s(_vm.$t("copyright")) + ".")]), _c('br'), _vm._v(" " + _vm._s(_vm.$t("poweredby")) + " "), _c('strong', [_vm._v("PROOFFICE")])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }